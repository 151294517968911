@cell-size: 18vmin;
@margin: 0.5vmin;
@total: 4 * @cell-size + 10 * @margin;

.unselectable() {
  user-select: none;
}

html, 
body {
    height: 100%;
}

body {
  display: block !important;
  background: hsv(10, 0.05, 1);
  overflow: hidden;
}

.header,
.footer {
  box-sizing: border-box;
  padding: 1vmin 0;
  margin: auto;
  width: @total;
  display: flex;
  justify-content: space-between;

  .title {
    flex: 2 0;
    line-height: 12vmin;
    font-size: 12vmin;
    margin: 1vmin 0 0 0;
    font-weight: bold;
    background-color: hsv(10, 0.05, 0.5);
    color: transparent;
    text-shadow: 0px 1px 2px hsv(10, 0.2, 0.9);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  }

  .score {
    text-align: center;
    margin: 0;
    border-radius: @cell-size / 20;
    flex: 1 0;
    font-size: 5vmin;
    color: #0008;
    font-weight: bold;
    background: hsv(10, 0.1, 0.9);
    box-shadow: inset 0 2px 4px #0004;
    h4 {
      font-weight: normal;
      font-size: 3vmin;
      margin: 1.8vmin 0 0.5vmin 0;
      opacity: 0.7;
    }

  }
}

.game {
  font-family: Helvetica, sans-serif;
}

.game-board {
  box-sizing: border-box;
  position: relative;
  margin: auto;
  width: @total;
  height: @total;

  white-space: nowrap;
  padding: @cell-size / 34;
  border-radius: @cell-size / 10;
  background: hsv(10, 0.1, 0.95);
  box-shadow: inset 0 1px 4px #0004;

  .cell {
    .unselectable();
    box-sizing: border-box;
    margin: @margin;
    width: @cell-size;
    height: @cell-size;
    line-height: @cell-size;
    background: #fff7;
    vertical-align: bottom;
    text-align: center;
    color: transparent;
    font-size: 0;

    border-radius: @cell-size / 15;
    font-family: sans-serif;
    font-weight: bold;

    opacity: 1;
    position: absolute;
    z-index: 1;
    transition: filter 0.5s;
   
    &.combined {
      text-shadow: 0 0 15px #ffa;
    }

    .cell(@max, @i: 2) when (@i =< @max) {
      &.cell-@{i} {
        z-index: 10;
        @color: hsv(@i*17, 0.3, 0.9);
        background: @color;
        color: contrast(@color, #0008, #fffc);
        font-size: @cell-size / 2;
        & when (@i/100 >= 1) {
          & {
            font-size: 0.47 * @cell-size;
          }
        }
        & when (@i/1000 >= 1) {
          & {
            font-size: 0.35 * @cell-size;
          }
        }
        & when (@i/10000 >= 1) {
          & {
            font-size: 0.3 * @cell-size;
          }
        }
      }
      .cell(@max, 2 * @i);
    }

    .cell(65536);
  }
}

.game-over-msg {
  box-sizing: border-box;
  font-weight: bold;
  position: absolute;
  top:0;
  left: 0;
  border-radius: @cell-size / 10;
  
  z-index: 200;
  line-height: 8vmin;
  font-size: 8vmin;
  background: #fff6;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 28vmin;
  color: #0006;
}

.game-over .cell {
  filter: blur(2px) grayscale(100%);

}

.footer {
  line-height: 2vmin;
  font-size: 2vmin;
  text-align: right;
  opacity: 0.5;
}

button {
  display: block;
  float: left;
  font-size: 2vmin;
}